<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB </v-subheader>

      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>MATLAB</v-list-tile-title>
          <v-list-tile-sub-title>Execute code to generate plot</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <pre v-highlightjs><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'CorrectedSpectrum',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      matlabCode: '',
      writeRun: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        this.writeMatlab()
      },
      deep: true
    }
  },
  mounted () {
    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)

    this.writeMatlab()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []
      let fileid = 0
      let collid = 0

      if (this.data.files) {
        if (this.data.files[0]) {
          fileid = this.data.files[0].fileid
          collid = this.data.files[0].colluuid
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      let rangemin = 0
      let rangemax = 0
      let nmmin = 0
      let nmmax = 0

      if (this.led.lotid === '25' && this.led.manid === '13') { // Crystal IS A019C1
        rangemin = 166
        rangemax = 318
        nmmin = 235
        nmmax = 280
      } else if (this.led.lotid === '23' && this.led.manid === '13') { // Crystal IS A017A1
        rangemin = 173
        rangemax = 325
        nmmin = 237
        nmmax = 282
      } else if (this.led.lotid === '27' && this.led.manid === '15') { // SETi TUD59H1B
        rangemin = 200
        rangemax = 352
        nmmin = 245
        nmmax = 290
      } else if (this.led.lotid === '29' && this.led.manid === '15') { // SETi TUD49H1B
        rangemin = 143
        rangemax = 294
        nmmin = 228
        nmmax = 273
      } else if (this.led.lotid === '31' || this.led.lotid === '35' || this.led.lotid === '37') { // Crystal IS UA2001-1-1/2/3
        rangemin = 173
        rangemax = 325
        nmmin = 237
        nmmax = 282
      }

      // current settings

      let ramp = ''
      let variable = ''
      let xlabel = ''
      let xunit = ''
      let mode = ''
      if (this.data.current_type !== 'constant') {
        if (this.data.current_shape === 'pulse') {
          variable = 'LED drive current set voltage [V]'
          xlabel = 'LED drive current set voltage'
          xunit = 'V'
          mode = 'pulsecurrent'
          if (this.data.current_type === 'linramp') {
            ramp = 'linspace(' + this.data.current_min + ',' + this.data.current_max + ',' + this.data.current_steps + ')'
          } else if (this.data.current_type === 'logramp') {
            ramp = 'logspace(log10(' + this.data.current_min + '),log10(' + this.data.current_max + '),' + this.data.current_steps + ')'
          }
        } else {
          variable = 'LED drive current [A]'
          xlabel = 'LED drive current'
          xunit = 'A'
          mode = 'current'
          if (this.data.current_type === 'linramp') {
            ramp = 'linspace(' + this.data.current_min / 1000 + ',' + this.data.current_max / 1000 + ',' + this.data.current_steps + ')'
          } else if (this.data.current_type === 'logramp') {
            ramp = 'logspace(log10(' + this.data.current_min / 1000 + '),log10(' + this.data.current_max / 1000 + '),' + this.data.current_steps + ')'
          }
        }
      } else if (this.data.pulse_type !== 'constant') {
        variable = 'Pulses per ' + this.data.pulse_frequency + 'seconds'
        xlabel = 'Pulses per ' + this.data.pulse_frequency + 'seconds'
        xunit = '1/s'
        mode = 'pulses'
        if (this.data.pulse_type === 'linramp') {
          ramp = 'linspace(' + this.data.pulses_min + ',' + this.data.pulses_max + ',' + this.data.pulses_steps + '); x = round(x)'
        } else if (this.data.pulse_type === 'logramp') {
          ramp = 'logspace(log10(' + this.data.pulses_min + '),log10(' + this.data.pulses_max + '),' + this.data.pulses_steps + '); x = round(x)'
        }
      } else if (this.data.duty_type !== 'constant') {
        variable = 'Duty cycle [%]'
        xlabel = 'Duty cycle [%]'
        xunit = '1'
        mode = 'duty'
        if (this.data.duty_type === 'linramp') {
          ramp = 'linspace(' + this.data.duty_min + ',' + this.data.duty_max + ',' + this.data.duty_steps + '); x = round(x)'
        } else if (this.data.duty_type === 'logramp') {
          ramp = 'logspace(log10(' + this.data.duty_min + '),log10(' + this.data.duty_max + '),' + this.data.duty_steps + '); x = round(x)'
        }
      }
      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% Get Spectra\n' +
        'db = {' + dbs.join(', ') + '};\n' +
        'uuids = {' + aos.join(', ') + '};\n' +
        '\n' +
        'aos = [];\n' +
        'for ii = 1:numel(uuids)\n' +
        'aos = [aos ao(plist(\'hostname\', \'localhost\', ...\n' +
        this.data.ltpda +
        '\'database\', db{ii}, ...\n' +
        '\'uuid\', uuids{ii}))];\n' +
        'end\n' +
        '\n' +
        'iplot(aos);\n' +
        '\n' +
        '% calculate normalized power spectra \n' +
        '\n' +
        'normPowerSpectra = [];\n' +
        '\n' +
        'for i = 1:numel(aos) %foreach spectrum\n' +
        '    %try to remove offset (top 500 data points should be zero mean)\n' +
        '    normPowerSpectrum = aos(i) - mean((aos(i).data.y(numel(aos(i).data.x)-499:numel(aos(i).data.x))));\n' +
        '    %from flux to power (count / wavelength)\n' +
        '    normPowerSpectrum = normPowerSpectrum  ./ aos(i).data.x;\n' +
        '    %normalize\n' +
        '    normPowerSpectrum = normPowerSpectrum ./ abs(sum(normPowerSpectrum));\n' +
        '    %reset the label\n' +
        '    normPowerSpectrum.setName(aos(i).name);\n' +
        '    %form new AO array\n' +
        '    normPowerSpectra = [normPowerSpectra normPowerSpectrum];\n' +
        'end\n' +
        '\n' +
        'iplot(normPowerSpectra);\n' +
        '\n' +
        '\n' +
        '% submit a \'normPowerSpectra\' array containting the normalized power spectra analysis objects to the LTPDA database\n' +
        '\n' +
        'normPowerSpectra_det = submit(normPowerSpectra, ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'norm_spectra\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': Normalized power spectra ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', \'Normalized power spectra\', ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        '\n' +
        '% upload normalized power spectra AOs to CMSdb\n' +
        '\n' +
        'submit_aolist(normPowerSpectra_det, \'' + this.data.files[0].name + '\', normPowerSpectra, \'A1\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '% create data file\n' +
        '\n' +
        'x = aos(i).data.x;\n' +
        '\n' +
        'fileID = fopen(\'xy.txt\', \'w\');\n' +
        '\n' +
        '    %write key labels\n' +
        '    fprintf(fileID, \'"%s" \', \'Wavelength [nm]\');\n' +
        '    for ii = 1:numel(aos) %foreach spectrum\n' +
        '       fprintf(fileID, \'"%s" \', normPowerSpectra(ii).name);\n' +
        '    end\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(x)\n' +
        '    fprintf(fileID, \'%e \', x(i));\n' +
        '    for ii = 1:numel(aos) %foreach spectrum\n' +
        '       fprintf(fileID, \'%e \', normPowerSpectra(ii).data.y(i));\n' +
        '    end\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        '\n' +
        'filetext = fileread(\'xy.txt\');\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'1\', \'\', filetext, \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '% UV power contribution\n' +
        '\n' +
        'x = ' + ramp + ';\n' +
        'y = [];\n' +
        'for i = 1:numel(normPowerSpectra)\n' +
        '    y = [y sum(normPowerSpectra(i).data.y(' + rangemin + ':' + rangemax + '))];\n' +
        'end\n' +
        '\n' +
        '% sub 254 nm power contribution\n' +
        '\n' +
        'z = [];\n' +
        'for i = 1:numel(normPowerSpectra)\n' +
        '    z = [z sum(normPowerSpectra(i).data.y(1:230))];\n' +
        'end\n' +
        '\n' +
        '% create data file\n' +
        '\n' +
        'fileID = fopen(\'xy.txt\', \'w\');\n' +
        '\n' +
        '%write key labels\n' +
        '    fprintf(fileID, \'"%s" \', \'' + variable + '\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + nmmin + '-' + nmmax + ' nm contribution\', \'< 254 nm contribution\');\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(x)\n' +
        '    fprintf(fileID, \'%e \', x(i));\n' +
        '    fprintf(fileID, \'%e \', y(i));\n' +
        '    fprintf(fileID, \'%e \', z(i));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        '\n' +
        'filetext = fileread(\'xy.txt\');\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'2\', \'' + mode + '\', filetext, \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '% generate contribution AO\n' +
        '\n' +
        'contribution = ao(plist(\'xvals\', x, \'yvals\', y, \'xunits\', \'' + xunit + '\', \'yunits\', \'1\', \'name\', \'' + this.led.internal_serialnumber + ' (' + nmmin + '-' + nmmax + ' nm)\', \'description\', \'' + nmmin + '-' + nmmax + ' nm contribution\'));\n' +
        'contribution.setXaxisName(\'' + xlabel + '\');\n' +
        'contribution.setYaxisName(\'Normalized power\');\n' +
        '\n' +
        'sub254 = ao(plist(\'xvals\', x, \'yvals\', z, \'xunits\', \'' + xunit + '\', \'yunits\', \'1\', \'name\', \'' + this.led.internal_serialnumber + ' (< 254 nm)\', \'description\', \'' + nmmin + '-' + nmmax + ' nm contribution\'));\n' +
        'sub254.setXaxisName(\'' + xlabel + '\');\n' +
        'sub254.setYaxisName(\'Normalized power\');\n' +
        '\n' +
        'iplot(contribution, sub254);\n' +
        'contributions = [contribution, sub254];\n' +
        '\n' +
        '% submit a \'contributions\' array containting the \'contribution\' analysis object to the LTPDA database\n' +
        '\n' +
        'contributions_det = submit(contributions, ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'uv_contributions\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': UV contributions from ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', \'UV Contributions (' + nmmin + '-' + nmmax + ' nm) and Sub 254 nm Contributions\', ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        '\n' +
        '% upload UV contribution AOs to CMSdb\n' +
        '\n' +
        'submit_aolist(contributions_det, \'' + this.data.files[0].name + '\', contributions, \'A2\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n'
      this.writeRun = true
    }
  }
}
</script>
<style scoped>
</style>
